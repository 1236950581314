module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/gatsby-icon.png"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"VirginiaGarridoMillan","accessToken":"MC5YZHZXYWhFQUFDSUFXVEdr.BjU677-9dO-_ve-_ve-_ve-_ve-_ve-_vTZiHThMHUkJ77-9CldBU0UBMO-_vWbvv717Ww","pages":[{"type":"Illustrations","match":"/illustrations/:uid","path":"illustrations","component":"/opt/build/repo/src/templates/illustration.js"},{"type":"Tatouages","match":"/tatouages/:uid","path":"tatouages","component":"/opt/build/repo/src/templates/tatouage.js"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-160983200-1"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
