// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("../node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-templates-illustration-js": () => import("../src/templates/illustration.js" /* webpackChunkName: "component---src-templates-illustration-js" */),
  "component---src-templates-tatouage-js": () => import("../src/templates/tatouage.js" /* webpackChunkName: "component---src-templates-tatouage-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-biographie-js": () => import("../src/pages/biographie.js" /* webpackChunkName: "component---src-pages-biographie-js" */),
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faqs-js": () => import("../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-illustrations-js": () => import("../src/pages/illustrations.js" /* webpackChunkName: "component---src-pages-illustrations-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tatouages-js": () => import("../src/pages/tatouages.js" /* webpackChunkName: "component---src-pages-tatouages-js" */)
}

