module.exports = {
  linkResolver(doc) {
    if (doc.type === 'illustrations') {
      return `/illustrations/${doc.uid}`;
    }
    if (doc.type === 'tatouages') {
      return `/tatouages/${doc.uid}`;
    }

    return '/';
  },
};